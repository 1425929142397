import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import DemoVideo from "../components/demo_video"
import TestimonialsAgents from "../components/testimonials_agents"
import MailchimpFlowFindingsForm from "../components/mailchimp_flow_findings_form"
import Footer from "../components/footer"

import cover from "../images/cover.png"
import wave from "../images/wave.svg"
import video from "../images/video.png"
import covers from "../images/covers.png"

const FlowFindings = () => (
  <Layout>
    <SEO
      title="How COVID-19 has affected South Africans"
      description="The first survey to give the tenants an opportunity to be heard"
      image={cover}
    />
    <Navigation />

    <section className="w-full md:px-10 mt-12">
      <div className="container mx-auto py-5">
        <header
          className="flex flex-col-reverse md:flex-row w-full top-0 max-w-4xl mx-auto"
          style={{ marginTop: "6vh" }}
        >
          <div className="w-full md:w-1/2 mt-12 md:mt-0">
            <div className="text-center md:text-left">
              <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-none tracking-tight max-w-md mx-auto md:mx-0">
                How <span className="wrap">COVID-19</span>
                has affected South Africans Tenants
              </h1>

              <h3 className="text-md md:text-xl text-gray-700 animated fadeIn max-w-xs mx-auto md:mx-0 mt-4">
                The first survey to give the tenants an opportunity to be heard
              </h3>
              <a href="#download">
                <button className="btn-primary mt-6 animated fadeIn">
                  <i className="fas fa-file text-sm mr-2"></i>Download Report
                </button>
              </a>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-5 md:p-0">
            <img src={cover} alt="" className="w-2/3 mx-auto" />
          </div>
        </header>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section
      id="demo"
      className="w-full text-center md:text-left px-10 md:px-0 pb-20"
    >
      <div className="container mx-auto flex flex-wrap">
        <div className="w-full md:w-1/2 flex relative">
          <img
            className="w-full mx-auto"
            style={{ height: "100%" }}
            src={video}
            alt=""
          />
          <div className="absolute w-full h-full flex">
            <div className="inline-block z-50 self-center mx-auto flex items-center font-medium text-sm text-white">
              <DemoVideo videoId="GyZzOkKt5_U">
                <span className="inline-flex items-center w-16 h-16 bg-orange-500 hover:bg-white hover:text-orange-500 rounded-full text-center">
                  <i className="inline-block w-full fas fa-play text-md"></i>
                </span>
              </DemoVideo>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 md:pl-20">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-700 leading-none max-w-xs mx-auto md:mx-0 mt-10 md:mt-0">
            Watch our live panel discussion
          </h2>
          <p className="inline-block text-md md:text-xl font-light mr-4 text-gray-400 mt-3 max-w-sm">
            The first survey into how COVID-19 has affected South African
            Tenants
          </p>
          <div className="w-full flex flex-wrap">
            <div className="w-full md:w-1/2 mt-5">
              <div className="flex">
                <div className="flex inline-block w-6 h-6 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-2">
                  <i className="w-full self-center text-xs fas fa-check"></i>
                </div>
                <h3 className="self-center text-left leading-tight lg:pr-10 text-sm font-medium text-gray-700">
                  Get the experts opinion
                </h3>
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-5">
              <div className="flex">
                <div className="flex inline-block w-6 h-6 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-2">
                  <i className="w-full self-center text-xs fas fa-chart-bar"></i>
                </div>
                <h3 className="self-center text-left leading-tight lg:pr-10 text-sm font-medium text-gray-700">
                  Hard hitting facts
                </h3>
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-5">
              <div className="flex">
                <div className="flex inline-block w-6 h-6 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-2">
                  <i className="w-full self-center text-xs fas fa-home"></i>
                </div>
                <h3 className="self-center text-left leading-tight lg:pr-10 text-sm font-medium text-gray-700">
                  A deep dive into the rental market
                </h3>
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-5">
              <div className="flex">
                <div className="flex inline-block w-6 h-6 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-2">
                  <i className="w-full self-center text-xs fas fa-user"></i>
                </div>
                <h3 className="self-center text-left leading-tight lg:pr-10 text-sm font-medium text-gray-700">
                  Get the tenants perspective
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="testimonialsAgents"
      className="container mx-auto text-center md:mt-20 rounded-xl bg-gray-100 py-20"
    >
      <h2 className="mx-auto leading-tight text-2xl md:text-4xl font-bold text-gray-700">
        This is what our <span className="wrap">panel</span> had to say
      </h2>

      <div className="mt-10 md:w-2/3 mx-auto">
        <TestimonialsAgents />
      </div>
    </section>

    <section
      id="download"
      className="w-full text-center md:text-left px-10 md:px-0 md:py-10"
    >
      <div className="container mx-auto flex flex-wrap mt-20">
        <div className="w-full md:w-1/2">
          <img className="w-2/3 mx-auto" src={covers} alt="" />
        </div>
        <div className="w-full md:w-1/2 ">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-700 leading-tight max-w-sm mx-auto md:mx-0">
            Download the <span className="wrap">FlowFindings</span> Report
          </h2>
          <p className="inline-block text-md md:text-xl font-light mr-4 text-gray-400 mt-3 max-w-sm mb-5">
            Insights from a cross section of SA’s biggest rental community
          </p>

          <MailchimpFlowFindingsForm />
        </div>
      </div>
    </section>

    <img className="w-full mt-10" src={wave} alt="" />

    <Footer />
  </Layout>
)

export default FlowFindings
